import styled from "styled-components"
import { Grid } from "./globalStyles"

export const TextBlockSection = styled.section`
  padding-block: var(--space-m);
`

export const TextBlockSectionInner = styled.div`
  /* Tablet Adjustments */
  @media (min-width: 680px) {
    ${Grid}
  }
`

export const TextBlockContent = styled.div`
  ol {
    list-style: none;
    counter-reset: custom-counter;

    > li {
      counter-increment: custom-counter;
      display: flex;
      flex-wrap: wrap;

      &::before {
        content: counter(custom-counter) " — ";
        flex: 0 0 max-content;
        margin-right: var(--space-s);
      }

      > p {
        flex: 1 0 80%;
      }

      > ul {
        list-style: disc;
        margin-inline-start: var(--space-l);
      }
    }
  }

  ul {
    margin-inline-start: var(--space-n);
    margin-block-end: var(--space-n);

    > li {
      padding-inline-start: var(--space-s);

      > p {
        margin-bottom: 0;
      }
    }
  }

  /* Tablet Adjustments */
  @media (min-width: 680px) {
    grid-column: 2 / span 4;
    padding-inline: var(--space-n);
  }

  /* Desktop Adjustments */
  @media (min-width: 1200px) {
    grid-column: 4 / span 5;
  }
`
