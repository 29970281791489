import React from "react"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { MARKS } from "@contentful/rich-text-types"

// Styles
import {
  TextBlockContent,
  TextBlockSection,
  TextBlockSectionInner,
} from "../styles/textBlockStyles"
import { Container } from "../styles/globalStyles"

const options = {
  renderMark: {
    [MARKS.BOLD]: text => <b>{text}</b>,
  },
}

const TextBlock = ({ title, text }) => {
  return (
    <TextBlockSection>
      <Container>
        <TextBlockSectionInner>
          <TextBlockContent>
            <h2>{title}</h2>
            {renderRichText(text, options)}
          </TextBlockContent>
        </TextBlockSectionInner>
      </Container>
    </TextBlockSection>
  )
}

export default TextBlock
